.input-wrap {
    label {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $title-color;
        margin-bottom: 10px;
    }
    input {
        width: 100%;
        font-size: 14px;
        line-height: 16px;
        color: $body-color;
        padding: 7px 10px;
        background: $white;
        border-radius: 4px;
        border: 1px solid rgba(154, 157, 158, 0.58);
        transition: all 0.3s ease;
        &::placeholder {
            color: $teritary-text-color;
        }
        &:focus {
            border: 1px solid $primary-brand-color;
        }
    }
    &.error {
        input,
        select {
            border: 1px solid $secondary-brand-color;
        }
        .error-text {
            font-size: 12px;
            color: $secondary-brand-color;
        }
    }
    .input-holder {
        position: relative;
        i {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 16px;
            color: #52545b;
            cursor: pointer;
        }
    }
    .password-strength {
        position: relative;
        .password-progress {
            position: relative;
            height: 7px;
            background: #e5e5e5;
            border-radius: 2px;
            &-fill {
                position: absolute;
                top: 0;
                left: 0;
                height: 7px;
                border-radius: 2px;
                transition: all 0.3s ease;
            }
        }
        p {
            position: absolute;
            top: -25px;
            right: 0;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: #219653;
            text-transform: uppercase;
        }
    }
}

input[type="file"],
input[type="checkbox"] {
    display: none;
}

//toggle slider
.toggle-slider {
    input:disabled + label {
        pointer-events: none;
        opacity: 0.5;
    }
    &__checkbox {
        width: initial;
        position: absolute;
        opacity: 0;
        &:checked + label .toggle-slider__ball {
            background: $secondary-brand-color;
            &:before {
                left: 17px;
            }
        }
        &:checked + label .toggle-slider__txt {
            color: $primary-text-color;
        }
    }
    &__label {
        @include flexbox();
        @include align-items(center);
    }
    &__ball {
        display: block;
        width: 30px;
        height: 15px;
        background: grey;
        border-radius: 10px;
        cursor: pointer;
        padding: 0;
        margin: 0;
        position: relative;
        transition: all 0.4s ease;
        &:before {
            content: "";
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background-color: $white;
            box-shadow: inset 0 1px 4px 0 rgba(221, 214, 214, 0.5), 0 0 2px 0 rgba(36, 16, 16, 0.4);
            position: absolute;
            top: 2px;
            left: 2px;
            transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }
    &__txt {
        font-weight: 500;
        color: $title-color;
        margin-left: 8px;
        cursor: pointer;
        &:hover {
            color: rgba($primary-text-color, 1);
        }
    }
}

.custom-checkbox {
    .checkbox {
        &-label {
            cursor: pointer;
        }
        &-icon-wrap {
            @include avatar(14px, 14px, $white, 0);
            min-width: 14px;
            line-height: 14px;
            border: 1px solid rgba(0, 0, 0, 0.54);
            &::before {
                content: "";
                font-family: "Material Icons";
                font-size: 14px;
                font-weight: $bold;
                display: inline-block;
                color: $white;
                @include transition(all 0.3s ease);
                position: relative;
                top: -1.5px;
                left: -1px;
            }
        }
        &-txt {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: $grey;
            margin-left: 10px;
        }
    }
    input:checked + .checkbox-label .checkbox-icon-wrap {
        background-color: $primary-brand-color;
        border: 1px solid transparent;
        &::before {
            content: "\e5ca";
            color: $white;
        }
    }
}

.custom-checkbox-type2 {
    .checkbox {
        &-label {
            cursor: pointer;
        }
        &-icon-wrap {
            @include avatar(14px, 14px, $white, 0);
            min-width: 14px;
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 2px;
            border: 2px solid $secondary-brand-color;
            &::before {
                content: "";
                font-family: "Material Icons";
                font-size: 14px;
                font-weight: $bold;
                display: inline-block;
                color: $white;
                @include transition(all 0.3s ease);
                position: relative;
                top: -1.5px;
                left: 0;
            }
        }
        &-txt {
            font-size: 14px;
            line-height: 16px;
            margin-left: 10px;
            color: $body-color;
        }
    }
    input:checked + .checkbox-label .checkbox-icon-wrap {
        background-color: $secondary-brand-color;
        border: 1px solid transparent;
        &::before {
            content: "\e5ca";
            color: $white;
        }
    }
}
input:read-only {
    background: #f5f5f5;
    cursor: default;
    :focus {
        border-color: rgba(154, 157, 158, 0.58) !important;
    }
}

// @media only screen and (min-width: 1024px) and (max-width: 1440px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) { 
//     .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
//         line-height: 14px !important;
//   }
  
//    }